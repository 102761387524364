import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  async copy () {
    const text = this.inputTarget instanceof HTMLInputElement
      ? this.inputTarget.value
      : this.inputTarget.textContent

    await navigator.clipboard.writeText(text)

    const icons = {
      default: this.element.querySelector('.default-icon'),
      success: this.element.querySelector('.success-icon')
    }

    icons.default.classList.toggle('hidden', true)
    icons.success.classList.toggle('hidden', false)

    setTimeout(() => {
      icons.default.classList.toggle('hidden', false)
      icons.success.classList.toggle('hidden', true)
    }, 2000)
  }
}
