/* global RAILS_ASSET_URL */

import { application } from './application'

import AnalyticsController from './analytics_controller'
import AutoSubmitController from './auto_submit_controller'
import BulkSelectionController from './bulk_selection_controller'
import DialogController from './dialog_controller'
import MdmTypeSelectorController from './mdm_type_selector_controller'
import MultiSelectController from './multi_select_controller'
import RemoveElementController from './remove_element_controller'
import SubmitOnChangeController from './submit_on_change_controller'
import TableController from './table_controller'
import TabsController from './tabs_controller'
import CopyToClipboardController from './copy_to_clipboard_controller'

application.register('analytics', AnalyticsController)
application.register('auto-submit', AutoSubmitController)
application.register('bulk-selection', BulkSelectionController)
application.register('dialog', DialogController)
application.register('mdm-type-selector', MdmTypeSelectorController)
application.register('multi-select', MultiSelectController)
application.register('remove-element', RemoveElementController)
application.register('submit-on-change', SubmitOnChangeController)
application.register('table', TableController)
application.register('tabs', TabsController)
application.register('copy-to-clipboard', CopyToClipboardController)

document.addEventListener('turbo:load', async () => {
  if (document.querySelector('[data-controller="code-editor"]')) {
    await import(RAILS_ASSET_URL('/code_editor_controller.js'))
  }
})
